import React from "react";

const SendPulsePopup = () => {
    return (
      <script
        async
        src="https://static.wdgtsrc.com/assets/loader.js"
        data-chats-widget-id="91535747-9890-4406-8838-8399495ab7eb"
        className="_iub_cs_activate"
        type="text/plain"
      />
    );
}

export default SendPulsePopup;
